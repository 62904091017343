import Home from './pages/Home.vue'
import About from './pages/About.vue'
import Impressum from './pages/Impressum.vue'
import Datenschutz from './pages/Datenschutz.vue'

export default {
  '/': Home,
  '/about': About,
  '/impressum': Impressum,
  '/datenschutz': Datenschutz
}
