<template>
  <standard v-bind:hero="hero">
    <section>
      <b-carousel :progress="true" progress-type="is-dark" :indicator="false" :pause-info="false">
        <b-carousel-item v-for="(image, i) in images" :key="i">
            <b-image
            :src="require('@/assets/'+image.asset)"
            :alt="image.alt"
            ratio="2by1"
            :rounded="rounded"
        ></b-image>
        </b-carousel-item>
      </b-carousel>
    </section>
  </standard>
</template>

<script>
  import Standard from '../layouts/standard.vue'

  export default {
    data: function () {
      return {
        images: [
          {
              alt: 'Babylon Berlin',
              asset: 'carousel/babylon-berlin.jpg'
          },
          {
              alt: 'Känguru Chroniken',
              asset: 'carousel/kaenguru-chroniken.jpg'
          },
          {
              alt: 'SZ Cinemathek',
              asset: 'carousel/sz-cinemathek.jpg'
          },
        ]
      }
    },
    components: {
      Standard
    }
  }
</script>
