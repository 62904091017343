import Vue from 'vue'
import Buefy from 'buefy'

import routes from './routes'
import errorView from './pages/404.vue'

Vue.config.productionTip = false
Vue.use(Buefy)

const app = new Vue({
  el: '#app',
  data: {
    currentRoute: window.location.pathname
  },
  computed: {
    ViewComponent () {
      const matchingView = routes[this.currentRoute]
      return matchingView
        ? matchingView
        : errorView
    }
  },
  render (h) {
    return h(this.ViewComponent)
  }
})

window.addEventListener('popstate', () => {
  app.currentRoute = window.location.pathname
})
