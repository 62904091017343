<template>
  <div id="app">
    <section class="hero header is-black is-small">
      <div class="hero-body">
          <div class="logo"><a href="/"><img src="../assets/logo-studio57.svg"> </a></div>
      </div>
    </section>

    <slot></slot>

    <Footer/>
  </div>
</template>

<style lang="scss">
  @import "@/scss/_variables.scss";
  @import "~bulma";
  @import "~buefy/src/scss/buefy";
  @import "@/scss/custom.scss";
</style>

<script>
  import Footer from '@/components/footer.vue'

  export default {
    components: {
      Footer
    }
  }
</script>
