<template>
  <standard>
    <section class="section container content has-text-centered">
      <h1 class="has-text-danger">Page not found</h1>
      <p><a href="/" class="button is-outlined">Back to Home</a></p>
    </section>
  </standard>
</template>

<script>
  import Standard from '../layouts/standard.vue'

  export default {
    components: {
      Standard
    }
  }
</script>
