<template>
  <standard>
    <main class="section container content">
      <h1 class="title is-size-1-tablet">About</h1>
      <p>
        Studio57 ist eine Kreativ-Agentur für digitale Kampagnen.
      </p>
      <p>
        Wir kreieren vom einzelnen Content-Highlight bis hin zu ganzheitlichen Digital-Strategien herausragende Erlebnisse. Wir stehen für emotionale Kommunikation von Marken und Menschen. Als Tochterunternehmen der <a href="https://www.x-verleih.de" target="_blank" rel="noopener noreferrer">X Verleih AG</a> arbeiten wir mit einem Netzwerk von preisgekrönten Bewegtbild- und Digital-Talenten zusammen, die auf über 20 Jahre Erfahrung in der Produktion fiktionaler Stoffe sowie der Kreation und Umsetzung von Kampagnen für Kinofilme und Serien zurückgreifen können.
      </p>
      <p>
        Für Referenzen schreiben Sie uns eine Mail an <a href="mailto:hello@studio57-berlin.de?subject=Ich%20will%20Referenzen%20von%20Studio57%20sehen!">hello@studio57-berlin.de</a>.
      </p>
    </main>
  </standard>
</template>

<script>
  import Standard from '../layouts/standard.vue'

  export default {
    data: function () {
        return { }
    },
    components: {
      Standard
    }
  }
</script>
